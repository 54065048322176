<template>
	<div class="suppierDetail">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix"><span>基本信息</span></div>
				<div class="content">
					<el-form-item label="活动名称：" prop="name">
						<el-input :disabled="isEditEnable" v-model="ruleForm.name" placeholder="最多输入20个汉字" maxlength="20" style="width: 300px;"></el-input>
					</el-form-item>

					<el-form-item label="活动时间：" required >
						<!-- <el-date-picker :disabled='isEditEnable'  v-model="currentTimeValue" @change="timeChange" type="datetimerange"  format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"
						 :default-time="['12:00:00']">
						</el-date-picker> -->
						<el-form-item prop="activeStartTime" class="el-form-date-pick">
							<el-date-picker
								:disabled="isEditEnable"
								v-model="ruleForm.activeStartTime"
								type="datetime" 
								format="yyyy-MM-dd HH:mm:ss" 
								value-format="yyyy-MM-dd HH:mm:ss" 
								placeholder="开始时间"
								:picker-options="maxOptions"
							></el-date-picker>
						</el-form-item>
						<span style="margin:0 10px;">~</span>
						<el-form-item prop="activeEndTime" class="el-form-date-pick">
							<el-date-picker
								:disabled="isEditEnable"
								v-model="ruleForm.activeEndTime"
								format="yyyy-MM-dd HH:mm:ss" 
								value-format="yyyy-MM-dd HH:mm:ss" 
								type="datetime"
								placeholder="结束时间"
								:picker-options="minOptions"
							></el-date-picker>
						</el-form-item>
					</el-form-item>

					<el-form-item label="优惠券：" required>
						<div style="color:#409EFF;cursor: pointer;" @click="chosCoupon">选择优惠券</div>
						<div style="font-size: 12px;color: #909399;line-height: 20px;">
							发放优惠券时，不受领取次数限制 ；若优惠券剩余数量少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充
						</div>
					</el-form-item>

					<el-form-item>
						<el-table border :data="tableDataList" style="width: 100%">
							<el-table-column label="优惠券" width="250px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<div class="right">
											<div class="name">{{ scope.row.CouponName }}</div>
											<span v-if="!scope.row.IsOpen || scope.row.LeaveCount==0">{{!scope.row.IsOpen ? '已失效': '数量=0'}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column label="赠送数量/人" width="140px">
								<template slot-scope="scope">
									<input :disabled="isEditEnable" style="width:100px;height: 30px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);" v-model="scope.row.CouponCount" ></input>
								</template>
							</el-table-column>
							<el-table-column label="操作" v-if="!isEditEnable">
								<template slot-scope="scope">
									<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row.Id)" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
							v-if="page.total"
							style="margin-top:20px;float:right;"
							@size-change="handleSizeChange"
							@current-change="handleSizeChange"
							:current-page="page.current"
							:page-sizes="[5, 10, 20, 30, 40, 50]"
							:page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.total"
						></el-pagination>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<!-- position:fixed;bottom:0;right:0;border-top:1px solid #ddd;background-color:#fff;width:100%; -->
		<div style="text-align:center;" v-if="!isEditEnable">
			<el-button style="margin:50px 30px;width:240px;" @click="suppilerCansel">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
		</div>
		<!-- dialog -->
		<!-- 选择优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="60%" class="dialog data-dialog">
			<select-coupon
				api="activitycouponpopupList"
				:selectedData="tableDataList"
				:params="['KeyWord', 'UseType']"
				@getSelectList="getSelectList"
				:visible.sync="selectCouponShow"
				v-if="selectCouponShow"
			></select-coupon>
		</el-dialog>
	</div>
</template>

<script>
import { CouponGiftModel, CouponGiftEdit } from '@/api/wyUsedInterface.js';
import selectCoupon from '@/components/SelectMultipCoupon';
export default {
	components: {
		selectCoupon
	},
	data() {
		return {
			loading: false,
			ruleForm: {
				name: '',
				activeStartTime: '',
				activeEndTime: ''
			},
			currentCount: '',
			rules: {
				name: [
					{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}
				],
				activeStartTime: [{
					required: true, 
					message: '请选择开始时间',
					trigger: 'blur'
				}],
				activeEndTime: [{
					required: true, 
					message: '请选择结束时间',
					trigger: 'blur'
				}],
			},
			// dialog
			selectCouponShow: false,
			searchKey: '',
			page: {
				total: 0,
				current: 0,
				size: 5
			},
			tableDataList: [],
			multipleSelection: [],
			selectCount: 5,
			checked: false,
			checkedShopPro: false,
			currentTimeValue: null,
			chosedCouponList: [],
			ActivityFullId: 0,
			btnType: 0, //0为添加  1为编辑   2为复制  3为查看
			addCouponType: 0,
			isEditEnable: false
		};
	},
	beforeMount() {
			this.btnType = this.$route.query.btnType;
			if (this.btnType == 0 ) {
				this.addCouponType = this.$route.query.type;
			} else if(this.btnType == 2){
				this.addCouponType = this.$route.query.type;
				this.ActivityFullId = this.$route.query.ActivityFullId;
			}else{
				this.ActivityFullId = this.$route.query.ActivityFullId;
			}
		if (this.btnType == 0) {
			//添加优惠券
		} else if (this.btnType == 1) {
			//编辑
			this.isEditEnable = false;
			this.getData();
		} else if (this.btnType == 2) {
			//复制
			this.isEditEnable = false;
			this.getData();
		} else if (this.btnType == 3) {
			//查看
			this.isEditEnable = true;
			this.getData();
		}
	},
	methods: {
		async getData() {
			this.loading =true
			try {
				let data = {
					ActivityFullId: this.ActivityFullId
				};
				let result = await CouponGiftModel(data);
				this.ActivityFullId = result.Result.ActivityFullId;
				this.ruleForm.name = result.Result.ActivityName;
				if(this.btnType == 2){
					this.ruleForm.activeStartTime = ''
					this.ruleForm.activeEndTime = ''
				}else{
					this.ruleForm.activeStartTime = result.Result.StartTime;
					this.ruleForm.activeEndTime = result.Result.EndTime;
				}
				
				this.tableDataList = result.Result.DetailsList;
				
			} catch (e) {
				this.loading = false
				//TODO handle the exception
			} finally{
				this.loading =false
			}
		},
		async saveData() {
			this.loading =true
			try {
				let details = []
				this.tableDataList.forEach((item,index)=>{
					let obj = {
						ActivityCouponId:item.Id,
						Count:parseInt(item.CouponCount),
					}
					details.push(obj)
				})
				let data = {
					ActivityFullId: (this.btnType == 0 || this.btnType == 2) ? 0 : this.ActivityFullId ,
					ActivityName: this.ruleForm.name,
					StartTime: this.ruleForm.activeStartTime,
					EndTime: this.ruleForm.activeEndTime,
					Details: details,
				};
				if(this.btnType==0||this.btnType==2){
					data.ActivityCouponGiftType = this.addCouponType
				}
				let result = await CouponGiftEdit(data);
				this.$message({showClose: true,
					type: 'success',
					message: '保存成功!'
				});
				this.$router.push({
					path: '/Customer/issueCoupon/index'
				});
			} catch (e) {
				this.loading = false
				//TODO handle the exception
			} finally{
				this.loading = false
			}
		},
		// 取消
		suppilerCansel() {
			this.$router.push({
				path: '/Customer/issueCoupon/index'
			});
		},
		chosCoupon(){
			if(this.isEditEnable){
				return;
			}
			this.selectCouponShow = true
			
		},

		// 保存
		saveSupiler(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.saveData();
					this.loading = false
				} else {
					return false;
				}
			});
		},
		handleFilter() {},
		timeChange() {
			if (this.currentTimeValue) {
				this.ruleForm.activeStartTime = this.currentTimeValue[0];
				this.ruleForm.activeEndTime = this.currentTimeValue[1];
			} else {
				this.ruleForm.activeStartTime = '';
				this.ruleForm.activeEndTime = '';
			}
		},
		handleSizeChange(val) {
			this.page.size = val;
		},
		handleCurrentChange(val) {
			this.page.current = val;
		},
		// 删除指定商品
		deleteSortPro(id) {
			this.$confirm('是否确认将该优惠券从指定列表中删除？', '提示', {
				confirmButtonText: '确认删除',
				cancelButtonText: '关闭',
				type: 'warning'
			})
				.then(() => {
					// let result = productCategoryDelete({
					// 	CategoryId: record.Id
					// })
					let dataList = this.tableDataList
					dataList.map((item,index)=>{
						if(item.Id == id){
							dataList.splice(index,1);
						}
					})
					this.tableDataList = dataList
					this.$message({showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					// this.getList()
				})
				.catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				})
				.finally(() => {
					// this.soetData();
					// this.getSort();
				});
		},
		//获取选择的优惠券列表
		getSelectList(val) {
			// this.tableDataList = val;
			this.selectCouponShow = false;
			val.map(item=>{
				item.CouponCount ='';
				return item;
			})
			//this.tableDataList.concat(val)
			var array = val
			var del = [];
			for (var i = 0, arrayLen = array.length; i < arrayLen; i++) {
				for (var j = 0, delLen = del.length; j < delLen; j++) {
					if (array[i].Id === del[j].Id) {
						break;
					}
				}
				if (j === delLen) {
					del.push(array[i]);
				}
			}
			
			for (var m = 0;m < this.tableDataList.length; m++) {
				for (var n = 0;n < del.length; n++) {
					if (this.tableDataList[m].Id === del[n].Id) {
						del[n].CouponCount = this.tableDataList[m].CouponCount
					}
				}
			}
			
			this.tableDataList = del
		},
	},
	computed: {
		//日期选择器限制选择
		minOptions: function() {
			let limitTime = this.ruleForm.activeStartTime;
			return {
				disabledDate(time) {
					if (limitTime) {
						return time < new Date(limitTime);
					}
					// else{
					//            return time < Date.now()
					//          }
				}
			};
		},
		//日期选择器限制选择
		maxOptions: function() {
			let limitTime = this.ruleForm.activeEndTime;
			return {
				disabledDate(time) {
					if (limitTime) {
						return (
							time > new Date(limitTime)
							// ||time < Date.now()
						);
					}
				}
			};
		}
	}
};
</script>

<style lang="less" scoped>
.suppierDetail {
	margin: 30px;

	.remarks {
		color: #bbb;
		font-size: 14px;
	}

	.parent-box {
		position: relative;

		.close-bosx {
			position: absolute;
			right: -5px;
			top: -13px;
			font-size: 18px;
		}
	}

	.markRed {
		position: absolute;
		left: -94px;
		top: 5px;
		color: red;
		font-size: 18px;
	}
}

.dialog {
	width: 100%;

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}
}

.table-container {
	position: relative;
	overflow: hidden;
}

.dialog-name-content {
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}

	.name {
		width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	span {
		color: #e51c23;
		font-size: 12px;
	}
}

.el-form-date-pick {
	display: inline-block;
}
</style>
